import React, { useState, useEffect, useRef } from 'react';
import VideoSemanticForSearch from './VideoSemanticForSearch';
import VideoAnswerForSearch from './VideoAnswerForSearch';

import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { fetchSearchDeleteMovie } from '../../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import { InputAdornment, TextField, Select, MenuItem, Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, CircularProgress, Menu, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Index, Semantic } from '../../configuration/Interface';
import DynamicTable from '../../../generalComponents/DynamicTable';
import VideoText from '../../VideoText';
import LoadingDots from '../LoadingDots';
import { createMonitoringOpenQuestion, createMonitoringDeleteAllQuestion, getMonitoringMaxByCourseIdAndDomain, getMonitoringUserCourseDomainMaxQuestion, resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay, createMonitoringLikedDislikedAllQuestion } from '../../../../api/serverApis/monitoringApi';
import { addToGlobalFAQ, addToPrivateFAQ, removeFromGlobalFAQ, removeFromPrivateFAQ } from '../../../../api/serverApis/combinationSearchApi';
import { getAllGlobalFAQData, getSearchByCourseId } from '../../../../features/combinationSearchSlice';
import { getUsers } from '../../../../api/serverApis/userApi';
import { SelectChangeEvent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { addLikeToQuestion } from '../../../../api/serverApis/combinationSearchApi';
import MenuIcon from '@mui/icons-material/Menu';
interface User {
    userId: string;
    email: string;
}
interface MainSearchProps {
    data: any;
    theme: any;
    resultOfOpeningAskQuestion: any;
    handleMainSearchFullScreen: any;
    currentView: string;
    courseId: string;
}

const MainSearchForSearchOnly: React.FC<MainSearchProps> = ({ courseId, handleMainSearchFullScreen, currentView, data, theme, resultOfOpeningAskQuestion }) => {

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<any | null>(null);
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({}); // Track loading state for each item
    const [fullScreenItem, setFullScreenItem] = useState<any>(null);
    const lightColors = ['white', '#F0F8FF']; // Softer shades for light theme
    const darkColors = ['black', '#353935']; // Deeper shades for dark theme
    const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
    const dispatch = useDispatch<AppDispatch>();
    const accordionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [prevAnswerDataLength, setPrevAnswerDataLength] = useState<number>(data?.answerData?.length || 0);
    const [maxQuestions, setMaxQuestions] = useState<number | null>(null);
    const [remainingQuestions, setRemainingQuestions] = useState<number | null>(null);
    const [starredQuestions, setStarredQuestions] = useState<string[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [differentUserId, setDifferentUserId] = useState('');
    const [permissions, setPermissions] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState(''); // For search input
    const [likeStatus, setLikeStatus] = useState<{ [key: string]: 'liked' | 'disliked' | null }>({});
    const domain = window.location.hostname;
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Update filtered data when searchTerm changes

    useEffect(() => {
        const savedPermissions = localStorage.getItem('permissions');
        if (savedPermissions) {
            setPermissions(JSON.parse(savedPermissions));
        }
    }, []);

    useEffect(() => {
        const starred = Array.isArray(data?.answerData)
            ? data?.answerData.filter((item: any) => item.faqAddToGlobal === true).map((item: any) => item.answerDataId)
            : [];
        setStarredQuestions(starred);
    }, [data]);

    useEffect(() => {
        if (selectedItem && data?.answerData) {
            // Find the updated item in the new data.
            const updatedItem = data.answerData.find(
                (item: any) => item.answerDataId === selectedItem.answerDataId
            );
            // If we found an updated version that differs from the current selectedItem,
            // update the state.
            if (updatedItem && updatedItem !== selectedItem) {
                setSelectedItem(updatedItem);
            }
        }
    }, [data?.answerData, selectedItem]);
    useEffect(() => {
        if (courseId) {
            // Fetch the max questions allowed and remaining questions
            fetchMaxAndRemainingQuestions(courseId);
        }
    }, [data?.answerData]);
    useEffect(() => {
        // Disable main scroll when component mounts
        document.body.style.overflow = 'hidden';
        return () => {
            // Re-enable scrolling when component unmounts
            document.body.style.overflow = 'auto';
        };
    }, []);
    const fetchMaxAndRemainingQuestions = async (courseId: string) => {
        try {
            await resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay(courseId);
            // Fetch max questions allowed for this course
            const maxQuestionsResponse = await getMonitoringMaxByCourseIdAndDomain(courseId);
            setMaxQuestions(maxQuestionsResponse?.data?.result || 0);

            // Fetch how many questions the user can still ask
            const remainingQuestionsResponse = await getMonitoringUserCourseDomainMaxQuestion(courseId);
            setRemainingQuestions(remainingQuestionsResponse?.data?.result || 0);
        } catch (error) {
            console.error('Error fetching max/remaining questions:', error);
        }
    };
    const goToFaqFullScreen = () => {
        if (currentView === 'search') {
            handleMainSearchFullScreen("video");
        }
        else {
            handleMainSearchFullScreen("search");
        }
    }
    const goAnalyzeContent = () => {
        if (currentView === 'analyze') {
            handleMainSearchFullScreen("video");
        }
        else {
            handleMainSearchFullScreen("analyze");
        }
    }
    const handleMovieDelete = () => {
        const item = deleteConfirmOpen;
        dispatch(fetchSearchDeleteMovie({ courseId: data.courseId, searchId: data.searchId, answerDataId: item?.answerDataId }));
        createMonitoringDeleteAllQuestion(data.courseId, data.searchId, item.answerDataId, item.answer.question);
        setDeleteConfirmOpen(null);
    };
    useEffect(() => {
        // Retrieve permissions from localStorage
        const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');

        // Check if the user has access to usersList in MainSearch
        const canAccessUsersList = permissions?.MainSearch?.usersList?.canAccess;

        // Fetch users only if canAccess is true
        if (canAccessUsersList) {
            const fetchUsers = async () => {
                try {
                    const response = await getUsers();
                    const sortedUsers = response.data.sort((a: any, b: any) => {

                        return a.email.localeCompare(b.email);
                    });
                    setUsers(sortedUsers); // Assume response.data contains an array of users
                } catch (error) {
                    console.error('Error fetching users:', error);
                }
            };
            fetchUsers();
        }
    }, []);

    const handleUserChange = (event: SelectChangeEvent<string>) => {
        const selectedUserId = event.target.value as string;
        setSelectedUser(event.target.value as string);
        // Additional logic for selected user can be added here
        if (selectedUserId && courseId) {
            setDifferentUserId(selectedUserId);
            dispatch(getSearchByCourseId({ userId: selectedUserId, courseId: courseId, searchId: "first0001" }));
        }
    };
    const handleStarClick = async (answerDataId: string) => {
        try {
            // Check if the question is currently starred
            const isCurrentlyStarred = starredQuestions.includes(answerDataId);

            // Toggle star locally
            setStarredQuestions(prev =>
                isCurrentlyStarred ? prev.filter(id => id !== answerDataId) : [...prev, answerDataId]
            );

            // Send request to server to update star status
            if (isCurrentlyStarred) {
                await removeFromGlobalFAQ(answerDataId, data.userId, data.courseId, data.searchId);
            } else {
                await addToGlobalFAQ(answerDataId, data.userId, data.courseId, data.searchId);
            }
        } catch (error) {
            console.error("Error toggling star status:", error);
        }
        dispatch(getAllGlobalFAQData({ userId: null, courseId: courseId, searchId: "first0001", answerDataId: null }));
    };
    //const sortedAnswerData = data && data.answerData ? [...data.answerData].sort((a: any, b: any) => new Date(b.answer.date).getTime() - new Date(a.answer.date).getTime()) : [];
    const sortedAnswerData = data && data.answerData
        ? [...data.answerData].sort((a: any, b: any) => {
            const dateA = a.answer?.date ? new Date(a.answer.date).getTime() : 0;
            const dateB = b.answer?.date ? new Date(b.answer.date).getTime() : 0;
            return dateB - dateA;
        })
        : [];

    const filteredData = searchTerm
        ? sortedAnswerData.filter((item: any) =>
            item.answer?.question?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : sortedAnswerData;
    const hasSentToDBOnExpand = useRef<string | false>(false);

    useEffect(() => {
        if (filteredData.length > 0) {
            const firstNonEmptySemanticPanel = filteredData.findIndex((item: any) =>
                item &&
                item.movies &&
                Array.isArray(item.movies) &&
                item.movies.some((movie: any) => movie?.semantic && movie?.semantic.length > 0)
            );
            const firstAnswerIfEmpty = filteredData.findIndex((item: any) => item?.answer && item.answer.answer);
            if (firstNonEmptySemanticPanel !== -1) {
                setLoadingStates(prev => ({ 'panel1': false }));

            }
            else if (firstAnswerIfEmpty !== -1) {
                setLoadingStates(prev => ({ 'panel1': false }));

            }

        }
    }, [data?.answerData]);

    useEffect(() => {
        if (data?.answerData) {
            const initialLikeStatus = data.answerData.reduce((acc: any, item: any) => {
                acc[item.answerDataId] = item.likeStatus || null; // Use `item.likeStatus` or default to null
                return acc;
            }, {});
            setLikeStatus(initialLikeStatus);
        }
    }, [data?.answerData]);

    const handleLike = (answerDataId: string, item: any) => {
        addLikeToQuestion(courseId, data.searchId, answerDataId, 'liked');
        setLikeStatus((prev) => ({ ...prev, [answerDataId]: 'liked' }));
        createMonitoringLikedDislikedAllQuestion(courseId, data.searchId, answerDataId, item.answer.question, 'liked');
    };

    const handleDislike = (answerDataId: string, item: any) => {
        addLikeToQuestion(courseId, data.searchId, answerDataId, 'disliked');
        setLikeStatus((prev) => ({ ...prev, [answerDataId]: 'disliked' }));
        createMonitoringLikedDislikedAllQuestion(courseId, data.searchId, answerDataId, item.answer.question, 'disliked');
    };
    const isHebrewOrArabic = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);

    const openingAskQuestion = async (searchId: string, answerDataId: string) => {
        await resultOfOpeningAskQuestion(searchId, answerDataId, differentUserId);
    };
    const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';
    const handleListItemClick = async (item: any) => {

        // Then fetch the updated data.
        await openingAskQuestion(item.searchId, item.answerDataId);
        setSelectedItem(item);
        // Optionally, you can call scrollToTopOfPage() or similar here if needed.
    };
    const renderTopControls = () => (
        <Box
            sx={{
                position: 'fixed',
                top: 55,
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between', // Keep everything aligned properly
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper, // Ensure visibility
                padding: '10px 20px',
                zIndex: 1000, // Keep it above other elements
            }}
        >
            {/* Left Side: Other Controls */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleClick}
                    startIcon={<MenuIcon />}
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.common.black,
                        border: `1px solid ${theme.palette.common.black}`,
                        textTransform: 'none',
                        '&:hover': { backgroundColor: theme.palette.grey[200] },
                    }}
                >
                    Options
                </Button>

                {/* Popover Menu for User Select & Actions */}
                <Popover
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    sx={{ mt: 1 }}
                >
                    <Box sx={{ p: 2, width: 250 }}>
                        {/* User Selection */}
                        {permissions?.MainSearch?.usersList?.canAccess && (
                            <Select
                                value={selectedUser}
                                onChange={handleUserChange}
                                displayEmpty
                                sx={{ width: '100%', mb: 2 }}
                            >
                                <MenuItem value="" disabled>Select User</MenuItem>
                                {users.map((user) => (
                                    <MenuItem key={user.userId} value={user.userId}>
                                        {user.email}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}

                        {/* Action Buttons */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={goToFaqFullScreen}
                                disabled={!data?.answerData || data.answerData.length === 0}
                                sx={{
                                    backgroundColor: theme.palette.common.white,
                                    color: theme.palette.common.black,
                                    border: `1px solid ${theme.palette.common.black}`,
                                    textTransform: 'none',
                                    '&:hover': { backgroundColor: theme.palette.grey[200] },
                                }}
                            >
                                {currentView === 'search' ? 'Back to Video' : 'Go Full Screen'}
                            </Button>

                            {permissions?.MainSearch?.analyzeButton?.canAccess &&
                                permissions?.MainSearch?.analyzeButton?.parameter1 === domain && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={goAnalyzeContent}
                                        sx={{
                                            backgroundColor: theme.palette.common.white,
                                            color: theme.palette.common.black,
                                            border: `1px solid ${theme.palette.common.black}`,
                                            textTransform: 'none',
                                            '&:hover': { backgroundColor: theme.palette.grey[200] },
                                        }}
                                    >
                                        {currentView === 'search' ? 'Back to Video' : 'Analyze Screen'}
                                    </Button>
                                )}
                        </Box>
                    </Box>
                </Popover>

                {/* Right: Remaining Questions Counter */}
                {maxQuestions && remainingQuestions && (
                    <Tooltip
                        title={`You can ask ${maxQuestions - remainingQuestions} more questions out of ${maxQuestions}.`}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#007BFF',
                                padding: '10px 20px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}
                        >
                            <Typography variant="h6">{remainingQuestions}/{maxQuestions}</Typography>
                        </Box>
                    </Tooltip>
                )}
            </Box>

            {/* Right Side: Move Search Filter Above the List */}
            <Box sx={{ width: '30%', display: 'flex', justifyContent: 'flex-end', marginRight: '3%' }}>
                <TextField
                    label="Filter your questions"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variant="outlined"
                    sx={{ width: '100%' }} // Take full width of the right side
                    placeholder="Search..."
                    InputProps={{
                        endAdornment: searchTerm && (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setSearchTerm('')}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );


    // Helper method: Left detail panel showing the selected item's details.
    const renderDetailPanel = () => (
        <Grid item xs={8} sx={{
            maxHeight: 'calc(100vh - 120px)', // Adjusted height for full-screen effect
            overflowY: 'auto', // Enable scroll inside this section
            paddingRight: '10px', // Add spacing from right side
            marginTop: '-2%'
        }}>
            <Box sx={{ p: 2 }}>
                {selectedItem ? (
                    <Box>
                        <VideoAnswerForSearch
                            item={selectedItem.answer}
                            data={data}
                            answerDataId={selectedItem.answerDataId}
                        />
                        {selectedItem.movies &&
                            selectedItem.movies.map((movie: any, mIndex: number) => (
                                <React.Fragment key={mIndex}>
                                    {movie.semantic &&
                                        movie.semantic.map((semantic: Semantic, sIndex: number) => (
                                            <Box sx={{ mt: 2 }} key={sIndex}>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        direction: isHebrewOrArabic(selectedItem.answer.question)
                                                            ? 'rtl'
                                                            : 'ltr',
                                                    }}
                                                >
                                                    {isHebrewOrArabic(selectedItem.answer.question)
                                                        ? `תימוכין ${sIndex + 1}:`
                                                        : `Reference ${sIndex + 1}:`}
                                                </Typography>
                                                <VideoSemanticForSearch
                                                    dataAnswerId={selectedItem.answerDataId}
                                                    data={semantic}
                                                    isOpen={false}
                                                />
                                            </Box>
                                        ))}
                                </React.Fragment>
                            ))}
                    </Box>
                ) : (
                    <Typography variant="h6">
                        Select a question from the list to view its details.
                    </Typography>
                )}
            </Box>
        </Grid>
    );
    // Helper method: Right list panel showing the headers (like the accordion headers).
    const renderListPanel = () => (
        <Box
            sx={{
                position: 'sticky',
                top: 97,
                maxHeight: '82vh',
                overflowY: 'auto',
                p: 1,
                // border: `1px solid ${theme.palette.divider}`,
                // borderRadius: '8px',
                width: '30%'
            }}
        >
            {filteredData &&
                filteredData.map((item: any, index: number) => (
                    <Box
                        key={index}
                        onClick={async () => await handleListItemClick(item)}
                        sx={{
                            borderBottom: '1px solid #ddd',
                            p: 1,
                            cursor: 'pointer',
                            backgroundColor:
                                fullScreenItem === item.answerDataId ? 'rgba(0, 123, 255, 0.1)' : 'inherit',
                            '&:hover': { backgroundColor: 'rgba(0, 123, 255, 0.1)' },
                        }}
                    >
                        <Box sx={{ direction: isHebrewOrArabic(selectedItem?.answer?.question) ? 'rtl' : 'ltr', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Tooltip title={item?.answer?.question || ''} placement="top">
                                <Typography noWrap sx={{ direction: isHebrewOrArabic(selectedItem?.answer?.question) ? 'rtl' : 'ltr', fontWeight: 'bold' }}>

                                    {item?.answer?.question
                                        ? item.answer.question.length > 40
                                            ? `${item.answer.question.slice(0, 40)}...`
                                            : item.answer.question
                                        : 'No question'}
                                </Typography>
                            </Tooltip>
                            {permissions?.MainSearch?.star?.canAccess && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleStarClick(item.answerDataId);
                                    }}
                                >
                                    <StarIcon
                                        sx={{
                                            color: starredQuestions.includes(item.answerDataId) ? 'gold' : 'gray',
                                        }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                            <Tooltip
                                title={
                                    item?.answer?.date
                                        ? new Date(item.answer.date).toLocaleTimeString('en-GB')
                                        : ''
                                }
                                placement="top"
                            >
                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {item?.answer?.date ? new Date(item.answer.date).toLocaleDateString() : ''}
                                </Typography>
                            </Tooltip>
                            <Tooltip title="Delete this section" placement="top">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteConfirmOpen(item);
                                    }}
                                >
                                    <DeleteIcon
                                        sx={{
                                            fontSize: '20px',
                                            color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                ))}
        </Box>
    );

    // Final return: assemble the top controls and two-column layout.
    return (
        <Box
            sx={{
                width: '100%',
                paddingBottom: '100px',

                overflow: 'hidden',
            }}
        >
            {renderTopControls()}
            <Grid container spacing={0} sx={{ height: 'calc(100vh - 60px)' }}>
                {renderDetailPanel()}
                {renderListPanel()}
            </Grid>
        </Box>
    );

}

export default MainSearchForSearchOnly;
