import React, { useState, useMemo, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Header from '../../components/combinationSearchComponents/Header';
import { getSearchByCourseId, getAllGlobalFAQData } from '../../features/combinationSearchSlice';
import { getSolverSearchByCourseId } from '../../features/solverSearchSlice';
import { getPlaylistSlice } from '../../features/playlistSlice';
import { fetchCourseMovieData, setSelectedVideoDetails } from '../../features/coursesSlice';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import VideoSearchLayoutOnlySearch from '../../components/combinationSearchComponents/mainComponenets/searchViewOnly/VideoSearchLayoutOnlySearch';
import getTheme from '../../components/combinationSearchComponents/configuration/Themes';
import { useNavigate } from 'react-router-dom';
import useStyles from '../../styles/useCoursePageStyles';
import { use } from 'passport';



const CoursePage = () => {
    const layoutsArray: any[] = [];
    const dispatch = useDispatch<AppDispatch>();
    const movieData = useSelector((state: RootState) => state.combinationSearch.data);
    const GlobalFaqData = useSelector((state: RootState) => state.combinationSearch.getAllGlobalFAQ);
    const combinationSearchState = useSelector((state: RootState) => state.combinationSearch);
    const solverSearchState = useSelector((state: RootState) => state.solverSearch);
    //const solverData = useSelector((state: RootState) => state.solverSearch.data);
    const selectedVideo = useSelector((state: RootState) => state.courses.selectedVideo);
    const courseMovieData = useSelector((state: RootState) => state.courses.courseMovieData);
    const playlistData = useSelector((state: RootState) => state.playlist.data);
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
    const theme = useMemo(() => getTheme(themeMode), [themeMode]);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const classes = useStyles(theme);

    const getCourseId = () => {
        if (selectedVideo && selectedVideo.courseId) {
            localStorage.setItem('courseId', selectedVideo.courseId);
            return selectedVideo.courseId;
        }
        if (!selectedVideo || !selectedVideo.courseId) {
            const courseId = localStorage.getItem('courseId');
            if (courseId) {
                return courseId;
            }
            if (!courseId) {
                navigate(`/courseseleted`);
            }
        }
    }
    useEffect(() => {
        if (combinationSearchState.loading === false) {
            //  setIsLoading(false);
        }
    }, [combinationSearchState.loading, combinationSearchState.error]);

    useEffect(() => {
        if (solverSearchState.loading === false) {
            //setIsLoading(false);
        }
    }, [solverSearchState.loading, solverSearchState.error]);



    useEffect(() => {
        if (selectedVideo && selectedVideo.movieId) {
            localStorage.setItem('selectedVideo', JSON.stringify(selectedVideo));
            dispatch(fetchCourseMovieData({ movieId: selectedVideo.movieId, id: selectedVideo.id }));

            // dispatch(fetchCourseMovieDataWithNewBreakDown({ movieId: selectedVideo.movieId, id: selectedVideo.id }));
        }
        if (!selectedVideo) {
            const videosIds = localStorage.getItem('selectedVideo');
            if (videosIds) {
                const parsedVideosIds = JSON.parse(videosIds);
                setSelectedVideoDetails({ movieId: parsedVideosIds.movieId, id: parsedVideosIds.id, courseId: parsedVideosIds.courseId });
                dispatch(fetchCourseMovieData({ movieId: parsedVideosIds.movieId, id: parsedVideosIds.id }));
                //dispatch(getQuizByArticleIdSlice({ articleId: parsedVideosIds.movieId }));
                // dispatch(fetchCourseMovieDataWithNewBreakDown({ movieId: parsedVideosIds.movieId, id: parsedVideosIds.id }));
            }
            if (!videosIds) {
                navigate(`/courseseleted`);
            }
        }
    }, [selectedVideo])


    useEffect(() => {
        let sessionId = localStorage.getItem('courseSessionId');
        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem('courseSessionId', sessionId);
        }
        dispatch(getSearchByCourseId({ userId: null, courseId: getCourseId(), searchId: "first0001" }));
        dispatch(getAllGlobalFAQData({ userId: null, courseId: getCourseId(), searchId: "first0001", answerDataId: null }));
    }, []);

    useEffect(() => {
        if (!movieData) {
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
    }, [movieData]);


    // useEffect(() => {
    //   if (!solverData) {
    //     setIsLoading(false);
    //     return;
    //   }
    //   setIsLoading(false);
    // }, [solverData]);


    useEffect(() => {
        const courseId = getCourseId();
        const playlistId = '-1';
        dispatch(getPlaylistSlice({ playlistId, courseId }));
    }, []);

    // Toggle theme mode function
    const toggleTheme = () => {
        setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* Outermost box to cover the full screen */}
            <Box sx={{ overflow: 'hidden', height: '100vh' }}>
                <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1200, backgroundColor: theme.palette.background.paper }}>
                    <Header header={"Xarcam Courses"} themeMode={themeMode} toggleTheme={toggleTheme} title={title} />
                </Box>
                <Box className={classes.mainContentBox} >
                    <Box className={classes.layoutsBox}>
                        {courseMovieData && <VideoSearchLayoutOnlySearch
                            searchData={movieData}
                            videoData={courseMovieData}
                            videoUrl={courseMovieData.movieLink}
                            theme={theme}
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                            playlistData={playlistData}
                            getCourseId={getCourseId}
                            globalFaqData={GlobalFaqData}
                        />}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default CoursePage;
