import React, { useEffect, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Button, CircularProgress, Container, Typography, Alert, Paper } from '@mui/material';
import * as yup from 'yup';
import { RootState } from '../../store';
import { LogInUser } from '../../features/userSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo1.png';
import { Disclaimer } from '../../components/authentication/DisclaimerComponent';
import { fetchContentOfCourse, setSelectedVideoDetails } from '../../features/coursesSlice';

interface FormValues {
    email: string;
    password: string;
}

const initialValues: FormValues = {
    email: '',
    password: '',
};

const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string().min(8, 'Password should be minimum 8 char length').required('Password is required'),
});

const HARD_CODED_COURSE_ID = 'QuantumPhysicsExplained'; // Replace with actual hardcoded courseId

export const LoginPageToSearch: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { status, error, isAuthenticated, data } = useSelector((state: RootState) => state.user);
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

    useEffect(() => {
        if (isAuthenticated && data) {
            setDisclaimerOpen(true);
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('permissions', JSON.stringify(data.permissions));
            localStorage.setItem('menuPermissions', JSON.stringify(data.menuPermissions));
        }
    }, [isAuthenticated, data]);

    useEffect(() => {
        if (disclaimerAccepted) {
            localStorage.setItem('disclaimerAccepted', 'true');
            navigateToCourse();
        }
    }, [disclaimerAccepted]);

    const onSubmit = async (values: FormValues, { setSubmitting }: any) => {
        try {
            dispatch(LogInUser(values));
        } catch (err) {
            console.log(err);
            setSubmitting(false);
        }
    };

    const handleCloseDisclaimer = (accepted: boolean) => {
        setDisclaimerOpen(false);
        if (accepted) {
            setDisclaimerAccepted(true);
        }
    };

    const navigateToCourse = async () => {
        await dispatch(fetchContentOfCourse(HARD_CODED_COURSE_ID));
        dispatch(setSelectedVideoDetails({
            movieId: HARD_CODED_COURSE_ID,
            id: '',
            courseId: HARD_CODED_COURSE_ID
        }));
        navigate('/search');
    };

    return (
        <Container maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
                <Paper elevation={3} style={{ padding: '2em', width: '100%' }}>
                    <img src={logo} alt="Logo" style={{ width: '120px', marginLeft: '120px' }} />
                    {error && <Alert severity="error">{error}</Alert>}
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {({ submitForm, isSubmitting }) => (
                            <Form>
                                <Field component={TextField} name="email" type="email" label="Email" fullWidth margin="normal" />
                                <Field component={TextField} type="password" label="Password" name="password" fullWidth margin="normal" />
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    {status === 'loading' ? <CircularProgress /> : <Button variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>Login</Button>}
                                </Box>
                            </Form>
                        )}
                    </Formik>
                    {disclaimerOpen && <Disclaimer open={disclaimerOpen} onCloseBoolean={handleCloseDisclaimer} />}
                </Paper>
            </Box>
        </Container>
    );
};

export default LoginPageToSearch;
