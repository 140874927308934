import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Modal, Typography, Box, useTheme, Paper, Stack, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import { Semantic, Index } from '../../configuration/Interface';
import { fetchQuestionDelete } from '../../../../features/combinationSearchSlice';
import HtmlBoxInteract from '../../mainComponenets/HtmlBoxInteract';
import { createMonitoringDeleteAnswerQuestion, createMonitoringLikedDislikedAnswerInQuestion } from '../../../../api/serverApis/monitoringApi';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { addLikeToAnswer } from '../../../../api/serverApis/combinationSearchApi';
import { use } from 'passport';
interface AnswerProps {
  item: {
    question: string;
    answer: string;
    questionId: string;
    likeStatus?: string;
  };
  data: Semantic | Index;
  dontShowDeleteButton?: boolean;
  answerDataId?: string;
}

const VideoAnswer: React.FC<AnswerProps> = ({ item, data, dontShowDeleteButton, answerDataId }) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [openVideoInteract, setOpenVideoInteract] = useState(false);
  const [likeStatus, setLikeStatus] = useState<'liked' | 'disliked' | null>(null);

  useEffect(() => {
    if (item.likeStatus) {
      setLikeStatus(item.likeStatus === 'liked' ? 'liked' : 'disliked');
    }
  }, [data.likeStatus]);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument;
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument;
      if (doc) {
        doc.open();
        doc.write(item.answer);
        doc.close();
      }
      if (iframeDoc) {
        const styleElement = iframeDoc?.createElement('style');
        styleElement.innerHTML = `body { background-color: ${theme.palette.background.default}; margin: 0; }`;
        iframeDoc?.head.appendChild(styleElement);
      }
      handleIframeLoad();
    }
  }, [item.answer]);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
    }
  };
  const handleSearchDelete = () => {
    dispatch(fetchQuestionDelete({ courseId: data.courseId, searchId: data.searchId, questionId: item.questionId }))
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    dispatch(fetchQuestionDelete({ courseId: data.courseId, searchId: data.searchId, questionId: item.questionId }));
    createMonitoringDeleteAnswerQuestion(data.courseId, data.searchId, item.questionId, item.question);
    setOpen(false); // Close the dialog after confirming deletion
  };
  const handleCloseVideoInteract = () => {
    setOpenVideoInteract(false);
  };
  const handleImageClick = () => {
    setOpenVideoInteract(true);
  };
  const handleLike = () => {
    addLikeToAnswer(data.courseId, data.searchId, answerDataId ? answerDataId : '', 'liked');
    setLikeStatus('liked');
    createMonitoringLikedDislikedAnswerInQuestion(data.courseId, data.searchId, answerDataId ? answerDataId : '', item.question, 'liked');
  };

  const handleDislike = () => {
    addLikeToAnswer(data.courseId, data.searchId, answerDataId ? answerDataId : '', 'disliked');
    setLikeStatus('disliked');
    createMonitoringLikedDislikedAnswerInQuestion(data.courseId, data.searchId, answerDataId ? answerDataId : '', item.question, 'disliked');
  };
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  return (
    <>
      <Box sx={{ width: "100%", borderRadius: '20px', padding: theme.spacing(2), margin: 'auto', marginTop: theme.spacing(3) }}>
        <Stack direction="row" alignItems="center" spacing={2}>

        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ marginTop: theme.spacing(4), direction: isHebrew(item.answer) ? 'rtl' : 'ltr' }}>
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <iframe
              ref={iframeRef}
              style={{
                width: '100%',
                height: 'auto', // Allow height to be dynamically set
                border: 'none',
                overflow: 'hidden',
              }}
              scrolling="no" // Prevents iframe scrollbars
              onLoad={handleIframeLoad}
            />
          </Box>
        </Stack>
        {/* Like and Dislike Section */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        >
          <Tooltip title="I like this">
            <IconButton
              sx={{
                color: likeStatus === 'liked' ? theme.palette.success.main : theme.palette.text.secondary,
              }}
              onClick={handleLike}
            >
              <ThumbUpIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="I don't like this">
            <IconButton
              sx={{
                color: likeStatus === 'disliked' ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              onClick={handleDislike}
            >
              <ThumbDownIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* End of Like and Dislike Section */}
        {!dontShowDeleteButton && <Tooltip title="Delete the question and answer">
          <IconButton
            sx={{
              position: 'relative',
              top: theme.spacing(0),
              right: theme.spacing(1),
              color: theme.palette.info.main,
              height: '1px',
            }}
            onClick={handleClickOpen}
          >
            <DeleteIcon sx={{ fontSize: '20px', }} />
          </IconButton>
        </Tooltip>}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this question and its answer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Modal
        open={openVideoInteract}
        onClose={handleCloseVideoInteract}
        sx={{
          // This centers the modal, adjust positioning as needed
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1400
        }}
      >
        <HtmlBoxInteract question={item.question} answer={item.answer} onClose={handleCloseVideoInteract} />
      </Modal>
    </>
  );
};

export default VideoAnswer;
