import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, Button, Drawer, useTheme, Typography, Divider, Paper } from '@mui/material';
import MainSearchForSearchOnly from './MainSearchForSearchOnly';
import { useNavigate } from 'react-router-dom';
//import TableOContentComponent from './TableOfContentComponent';
import SearchBox from '../../SearchBox';
import { RootState, AppDispatch } from '../../../../store';
import { fetchCombinationSearch, getSearchByCourseId, getSearchByCourseIdAndAnswerDataId, getAllGlobalFAQData } from '../../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay } from '../../../../api/serverApis/monitoringApi';

interface VideoSearchLayoutProps {
    videoUrl: string;
    theme: any;
    searchData: any;
    videoData: any;
    globalFaqData: any;
    setIsLoading: any;
    isLoading: boolean;
    // solverSearchData?: any;
    playlistData?: any;
    handlePlaylistSelectClick?: any;
    getCourseId: () => string;
}
const VideoSearchLayoutOnlySearch: React.FC<VideoSearchLayoutProps> = ({ searchData, globalFaqData, videoData, videoUrl, theme, setIsLoading, isLoading, playlistData, handlePlaylistSelectClick, getCourseId }) => {
    const [openDrawer, setOpenDrawer] = useState<string | null>('toc');
    const muiTheme = useTheme();

    const dispatch = useDispatch<AppDispatch>();
    const courseId = getCourseId();
    const [currentView, setCurrentView] = useState<string>('video');
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('disclaimerAccepted')) {
            navigate('/login'); // Redirect to login or an appropriate page if disclaimer is not accepted
        }
    }, []);

    //*** quiz or question*** */


    //*******for ASK ********/ */
    const onSearch = async (searchTerm: string, time?: string, articleId?: string) => {
        try {
            setIsLoading(true);
            setOpenDrawer('ask');
            const sessionId = localStorage.getItem('courseSessionId');
            const searchLevels = JSON.parse(localStorage.getItem('searchLevels') || '[]');
            //const searchId = searchLevels[currentLevel];
            const searchId = "first0001";
            const courseIdLocal = getCourseId();
            const audioName = '';
            const onlySearch = '';
            const customer = '';
            const modelName = localStorage.getItem('modelName');
            const numberOfSearchResults = localStorage.getItem('numberOfSearchResults');
            const bestResultMethod = localStorage.getItem('bestResultMethod');
            const longContext = localStorage.getItem('longContext');
            const answerDataId = uuidv4();
            // dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "all" }));
            await resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay(courseIdLocal);
            dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "create", answerDataId }))
            dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "lines", answerDataId }));
            dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId: courseIdLocal, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType: "answers", answerDataId }));
        }
        catch (e) {
            console.log(e);
        }
        finally {
            //setIsLoading(false);
        }
    };
    const resultOfOpeningAskQuestion = (searchId: string, answerDataId: string, userId?: string) => {
        dispatch(getSearchByCourseIdAndAnswerDataId({ userId: userId ? userId : null, courseId, searchId, answerDataId }));
    }

    ////********* for mainSearchFullScreen */


    const customScrollbar = {
        '&::-webkit-scrollbar': {
            width: '18px', // Adjust scrollbar width
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Track background
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', // Thumb color
            borderRadius: '4px', // Rounded scrollbar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Darker on hover
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '97vh',
                overflowY: 'hidden',
                backgroundColor: theme.palette.background.paper,
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically if needed
            }}
        >
            <Box
                sx={{
                    width: '100%', // Set MainSearch to 70% width
                    height: '94vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Ensures content inside stays centered
                    marginTop: '20px',
                    ...customScrollbar,
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                <MainSearchForSearchOnly
                    courseId={courseId}
                    currentView={currentView}
                    handleMainSearchFullScreen={() => { }}
                    data={searchData}
                    theme={theme}
                    resultOfOpeningAskQuestion={resultOfOpeningAskQuestion}
                />
            </Box>

            <Box
                position="fixed"
                color="default"
                sx={{
                    border: '0px solid',
                    bottom: openDrawer ? 0 : 0,
                    width: '70%',
                    right: 200,
                    zIndex: 1350,
                    background: theme.palette.background.Paper,
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    transform: 'translateY(-10px)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
                        transform: 'translateY(-15px)',
                    },
                    opacity: 0.95,
                    backdropFilter: 'blur(10px)',
                    ...customScrollbar,
                }}
            >
                <SearchBox onSearch={onSearch} isLoading={isLoading} placeholder="Ask" />
            </Box>
        </Box>

    );
};

export default VideoSearchLayoutOnlySearch;