import React, { useState, useRef, useEffect } from 'react';
import { Semantic, Index } from '../../configuration/Interface';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import VideoBoxInteract from '../../VideoBoxInteract';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ScoreIcon from '@mui/icons-material/EmojiEvents'; // Icon for score
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Icon for duration
import MovieIcon from '@mui/icons-material/Movie'; // Icon for video title
import StartTimeIcon from '@mui/icons-material/PlayArrow'; // Icon for start time
import EndTimeIcon from '@mui/icons-material/Stop'; // Icon for end time
import PageIcon from '@mui/icons-material/InsertDriveFile'; // Icon for PDF page
import { fetchMovieDeleteMovie } from '../../../../features/combinationSearchSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { saveMovieToPlaylistSlice } from '../../../../features/playlistSlice';
import { getImagesFiles } from '../../../../api/serverApis/filesApi';
import { handleTimes } from '../../../searchComponents/UtilityFunctions';
import { createMonitoringDeleteMovieQuestion, createMonitoringAddToPlaylistUsage, createMonitoringLikedDislikedMovieInQuestion } from '../../../../api/serverApis/monitoringApi';
import pdfImage from '../../../../images/pdfImage.jpg';
import videoImage from '../../../../images/videoImage.jpg';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { addLikeToMovie } from '../../../../api/serverApis/combinationSearchApi';
import { wrap } from 'module';
interface SemanticProps {
  data: Semantic | Index;
  isOpen: boolean;
  dontShowDeleteButton?: boolean;
  dataAnswerId?: string;
}

const VideoSemanticForSearch: React.FC<SemanticProps> = ({ data, isOpen, dontShowDeleteButton, dataAnswerId }) => {
  const theme = useTheme();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const videoCardRef = useRef<HTMLDivElement>(null);
  const [showMovie, setShowMovie] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0); // Track the number of retries
  const [likeStatus, setLikeStatus] = useState<'liked' | 'disliked' | null>(null);

  const maxRetries = 3; // Maximum number of retry attempts
  const retryDelay = 2000; // Delay between retries in milliseconds
  const dispatch = useDispatch<AppDispatch>();

  const fetchImage = async (pdfImageLocation: string, setImageUrl: any, setRetryCount: any, retryCount: number, maxRetries: number, retryDelay: any) => {
    try {
      const response = await getImagesFiles(pdfImageLocation);
      if (response.status !== 200) throw new Error(`Failed to fetch image, Status: ${response.status}`);

      const imageBlob = new Blob([response.data], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(imageBlob);
      setImageUrl(imageUrl);

    } catch (error) {
      console.error('Error fetching image:', error);
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount((prev: any) => prev + 1);
          fetchImage(pdfImageLocation, setImageUrl, setRetryCount, retryCount, maxRetries, retryDelay); // Retry
        }, retryDelay);
      } else {
        setImageUrl(pdfImage); // Set to the fallback image after retries
      }
    }
  };


  useEffect(() => {
    if (data.movieLink.includes('.pdf') && data.pdfImageLocation) {
      fetchImage(data.pdfImageLocation, setImageUrl, setRetryCount, retryCount, maxRetries, retryDelay);
    }
  }, [data.pdfImageLocation, retryCount]);

  const handleImageClick = () => {
    setShowMovie(true);
  };

  const handleTimesLocally = (duration?: boolean) => {
    if (!data.pageNumber || data.pageNumber === '-1') {
      const { startTime, endTime, frameTime } = data;
      const sTime = startTime ? startTime.toString() : '';
      const eTime = endTime ? endTime.toString() : '';
      const fTime = frameTime ? frameTime.toString() : '';
      return handleTimes(sTime, eTime, fTime, duration);
    }
    if (data.pageNumber || data.pageNumber !== '-1') {
      return `Page: ${data.pageNumber}`;
    }
  };

  const isPdf = () => {
    return data.movieLink.indexOf('.pdf') !== -1;
  };

  const getIconForFileType = () => {
    const isPDF = data.movieLink.indexOf('.pdf') !== -1;
    return isPDF ? <PictureAsPdfIcon fontSize="large" /> : <PlayCircleOutlineIcon fontSize="large" />;
  };

  const handleMovieDelete = () => {
    setDeleteConfirmOpen(false);
    createMonitoringDeleteMovieQuestion(data.courseId, data.searchId, data.searchIdMetaData, data.articleId);
    dispatch(fetchMovieDeleteMovie({ courseId: data.courseId, searchId: data.searchIdMetaData, searchIdMovie: data.searchId, articleId: data.articleId }));
  };

  const handleAddToPlaylist = () => {
    dispatch(saveMovieToPlaylistSlice({ playlistData: data }));
    createMonitoringAddToPlaylistUsage(data.courseId, data.articleId, data.audioFileName, data.startTime ? data.startTime : '', data.endTime ? data.endTime : '', data.pageNumber ? data.pageNumber : '', data.pageNumber === "-1" ? "video" : "pdf", data.title);
  };

  const handleToggleMovie = () => {
    setShowMovie(!showMovie);
  };

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';
  useEffect(() => {
    // Set the initial likeStatus from the data object
    if (data.likeStatus) {
      setLikeStatus(data.likeStatus === 'liked' ? 'liked' : 'disliked');
    }
  }, [data.likeStatus]);
  const handleLike = () => {
    addLikeToMovie(data.courseId, data.searchIdMetaData, dataAnswerId ? dataAnswerId : '', data.articleId, data.vectorId, 'liked');
    setLikeStatus('liked');
    createMonitoringLikedDislikedMovieInQuestion(data.courseId, data.searchIdMetaData, dataAnswerId ? dataAnswerId : '', data.vectorId, data.title, 'liked', data.pageNumber === "-1" ? "video" : "pdf", data.startTime ? data.startTime : '', data.endTime ? data.endTime : '', data.frameTime ? data.frameTime : '', data.pageNumber ? data.pageNumber : '', data.score ? data.score : '');
  };

  const handleDislike = () => {
    addLikeToMovie(data.courseId, data.searchIdMetaData, dataAnswerId ? dataAnswerId : '', data.articleId, data.vectorId, 'disliked');
    setLikeStatus('disliked');
    createMonitoringLikedDislikedMovieInQuestion(data.courseId, data.searchIdMetaData, dataAnswerId ? dataAnswerId : '', data.vectorId, data.title, 'disliked', data.pageNumber === "-1" ? "video" : "pdf", data.startTime ? data.startTime : '', data.endTime ? data.endTime : '', data.frameTime ? data.frameTime : '', data.pageNumber ? data.pageNumber : '', data.score ? data.score : '');
  };
  const mediaSection = () => {
    return (
      <Box sx={{ position: 'relative', width: isPdf() ? '100%' : '50%', height: isPdf() ? 'auto' : '150px' }}>
        <CardMedia
          component="img"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          image={isPdf() ? (imageUrl || pdfImage) : (data.pageImage || videoImage)}
          alt={isPdf() ? 'PDF IMAGE' : 'VIDEO IMAGE'}
          onError={(e) => {
            e.currentTarget.src = isPdf() ? pdfImage : videoImage;
          }}
          onClick={handleImageClick}
        />

        {!isPdf() && (
          <Tooltip title="Play Video">
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Optional dark background for better visibility
                borderRadius: '50%',
                padding: '8px',
                cursor: 'pointer',
                zIndex: 5, // Ensures it's above everything
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleToggleMovie}
            >
              <PlayCircleOutlineIcon sx={{ fontSize: '50px', color: 'white' }} />
            </Box>
          </Tooltip>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '0.5rem', alignItems: 'center' }}>
          <MovieIcon sx={{ fontSize: '20px', color: theme.palette.secondary.main, mr: 1 }} />
          <Typography variant="subtitle2" sx={{ fontSize: '1rem' }}>
            Title: {data.title}
          </Typography>
        </Box>
      </Box>
    )
  }
  const informationSection = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem', flexWrap: 'nowrap' }}>

        {/* Score & Page/Duration Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={`Score: ${data.score}`}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <ScoreIcon sx={{ fontSize: '20px', color: theme.palette.success.main, mr: 1 }} />
              {data.score}
            </Typography>
          </Tooltip>

          {isPdf() ? (
            <Tooltip title={`Page: ${data.pageNumber}`}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <PageIcon sx={{ fontSize: '20px', color: theme.palette.info.main, mr: 1 }} />
                {data.pageNumber}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip title={`${handleTimesLocally(true)}`}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeIcon sx={{ fontSize: '20px', color: theme.palette.info.main, mr: 1 }} />
                {handleTimesLocally(true)}
              </Typography>
            </Tooltip>
          )}
        </Box>

        {/* Like/Dislike Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title="I like this">
            <IconButton
              sx={{
                color: likeStatus === 'liked' ? theme.palette.success.main : theme.palette.text.secondary,
              }}
              onClick={handleLike}
            >
              <ThumbUpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="I don't like this">
            <IconButton
              sx={{
                color: likeStatus === 'disliked' ? theme.palette.error.main : theme.palette.text.secondary,
              }}
              onClick={handleDislike}
            >
              <ThumbDownIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Delete, Add to Playlist, and View Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {!dontShowDeleteButton && (
            <Tooltip title="Delete the movie">
              <IconButton
                sx={{
                  color: theme.palette.info.main,
                }}
                onClick={() => setDeleteConfirmOpen(true)}
              >
                <DeleteIcon sx={{ fontSize: '25px' }} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Add to Playlist">
            <IconButton
              sx={{
                color: theme.palette.info.main,
              }}
              onClick={handleAddToPlaylist}
            >
              <PlaylistAddIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          </Tooltip>

          {isPdf() ? (
            <Tooltip title="View Pdf">
              <IconButton
                sx={{
                  color: theme.palette.info.main,
                }}
                onClick={handleToggleMovie}
              >
                <PictureAsPdfIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Play Video">
              <IconButton
                sx={{
                  color: theme.palette.info.main,
                }}
                onClick={handleToggleMovie}
              >
                <PlayCircleOutlineIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  };

  const dialogSection = () => {
    return (
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this movie?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleMovieDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const videoInteractSection = () => {
    return (
      <Box sx={{ position: 'fixed', top: 10, left: 0, zIndex: 9000 }}>
        <VideoBoxInteract
          index={data}
          onClose={handleToggleMovie}
          yBox={0}
          xBox={100}
          widthBox={isPdf() ? 800 : 700}  // Wider for PDF
          heightBox={isPdf() ? 700 : 650}  // Higher for PDF
          zIndex={9009}
        />
      </Box>
    )
  }
  const summarySection = () => {
    return (<CardContent sx={{ paddingBottom: '1rem' }}>
      <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: 'bold', direction: isHebrew(data.summaryText) ? 'rtl' : 'ltr', textAlign: isHebrew(data.summaryText) ? 'right' : 'left' }}>
        {data.summaryText}
      </Typography>
    </CardContent>)
  }
  return (
    <Box>
      <Box
        ref={videoCardRef}
        sx={{
          display: isOpen ? 'none' : 'block',
          maxWidth: '100%',
          mb: 2,
          cursor: 'pointer',
          backgroundColor: theme.palette.background.paper,
          margin: '1rem',
          // borderRadius: '1rem',
          //border: '1px solid',
          // borderColor: borderColor,
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: isHebrew(data.summaryText) ? 'row' : 'row-reverse',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            textAlign: isHebrew(data.summaryText) ? 'left' : 'right',
            // justifyContent: isHebrew(data.summaryText) ? 'flex-start' : 'flex-end'
            //justifyContent: 'space-between',
            gap: '1rem',
            minHeight: '200px'
          }}
          onClick={handleImageClick}
        >
          {/* Summary Section - Above PDF, Wraps for Videos */}
          {isPdf() && <Box> {summarySection()}</Box>}
          {/* Media Section */}
          {mediaSection()}
          {/* Summary Section - Wraps Around Image */}
          {!isPdf() && (
            <Box sx={{ marginTop: '-3%', flex: 2, minWidth: '250px', maxWidth: 'calc(100% - 50%)' }}>
              {summarySection()}
            </Box>
          )}
        </Box>
        {/* Information Section (Opposite Side) */}
        <Box>
          {informationSection()}
        </Box>
        <Divider />
      </Box>
      {/* Dialog Section */}
      {dialogSection()}
      {showMovie && (videoInteractSection())}
    </Box>
  );
};

export default VideoSemanticForSearch;
